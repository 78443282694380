<template>
    <div>
        <b-row class="match-height ">
            <b-col md="6">
                <b-card class="bg-gradient-primary" text-variant="center">
                    <!-- <feather-icon size="30" icon="BellIcon" class="text-white" /><br> -->
                    <!-- <feather-icon size="30" icon="AwardIcon" class="text-white" />  -->
                    <h4 style="font-size : 50px" class="text-white"><strong>{{ rekap.umkm }}</strong></h4>
                    <p class="card-text font-small-3"> Mitra / Kelompok UMKM</p>
                    <!-- <b-button block variant="primary">
                        <feather-icon size="20" icon="BellIcon" class="text-white" /><br>
                        <small>Detail</small>
                    </b-button> -->
                </b-card>
            </b-col>
            <b-col md="6">

                <b-card class="bg-gradient-info" text-variant="center">
                    <!-- <feather-icon size="30" icon="BellIcon" class="text-white" /><br> -->
                    <!-- <feather-icon size="30" icon="AwardIcon" class="text-white" />  -->
                    <h4 style="font-size : 50px" class="text-white"><strong>{{ rekap.alat }}</strong></h4>
                    <p class="card-text font-small-3"> Jumlah Alat Produksi</p>
                    <!-- <b-button block variant="primary">
                        <feather-icon size="20" icon="BellIcon" class="text-white" /><br>
                        <small>Detail</small>
                    </b-button> -->
                </b-card>
            </b-col>
        </b-row>
        <b-row class="match-height ">
            <b-col md="6">
                <b-card class="bg-gradient-warning" text-variant="center">
                    <!-- <feather-icon size="30" icon="BellIcon" class="text-white" /><br> -->
                    <!-- <feather-icon size="30" icon="AwardIcon" class="text-white" />  -->
                    <h4 style="font-size : 50px" class="text-white"><strong>{{ rekap.pengajuan }}</strong></h4>
                    <p class="card-text font-small-3"> Pengajuan Peminjaman</p>
                    <!-- <b-button block variant="primary">
                        <feather-icon size="20" icon="BellIcon" class="text-white" /><br>
                        <small>Detail</small>
                    </b-button> -->
                </b-card>
            </b-col>
            <b-col md="6">
                <b-card class="bg-gradient-warning" text-variant="center">
                    <!-- <feather-icon size="30" icon="BellIcon" class="text-white" /><br> -->
                    <!-- <feather-icon size="30" icon="AwardIcon" class="text-white" />  -->
                    <h4 style="font-size : 50px" class="text-white"><strong>{{ rekap.tervalidasi }}</strong></h4>
                    <p class="card-text font-small-3"> Pengajuan Tervalidasi</p>
                    <!-- <b-button block variant="primary">
                        <feather-icon size="20" icon="BellIcon" class="text-white" /><br>
                        <small>Detail</small>
                    </b-button> -->
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BAvatar,
    BButton
} from 'bootstrap-vue'
export default {
    components: {
        BRow,
        BCol,
        BCard,
        BAvatar,
        BButton
    },
    props: {
        rekap: {}
    }
}
</script>

<style></style>