<template>
  <div>
    <b-row class="match-height">
      <b-col md="12">
        <b-row class="mb-1 mt-2">
          <b-col md="6" class="mt-5">
            <b-img :src="require('@/assets/images/logo/dasboard.png')" class="congratulations-img-left" fluid />
          </b-col>
          <b-col md="6">
            <histori-pembayaran :dataCout=dataCout></histori-pembayaran>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="12">
        <b-card>
          <h5>
            <b-avatar rounded="" variant="light-warning" class="mr-1">
              <feather-icon icon="BellIcon" size="25" />
            </b-avatar> Penjadwalan Peminjaman Alat Produksi
          </h5>
          <hr>
          <jadwal-peminjaman></jadwal-peminjaman>
        </b-card>

      </b-col>

    </b-row>
    <b-row>
      <b-col md="5">

      </b-col>
    </b-row>
  </div>
</template>

<script>
import Base from '@/config/Mixins_base';
import axios from '@/config/Axios';
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BButton,
  BCard,
  BCardGroup,
  BCardText,
  BImg
} from 'bootstrap-vue'
import profilMahasiswa from './componet/profilMahasiswa.vue'
import timeline from './componet/timeLinePembayaran.vue'
import historiPembayaran from './componet/InfoPembayaran.vue'
import JadwalPeminjaman from './componet/jadwalPeminjaman.vue';
export default {
  components: {
    timeline,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BButton,
    BCard,
    profilMahasiswa,
    BCardGroup,
    BCardText,
    BImg,
    historiPembayaran,
    JadwalPeminjaman
  },
  mixins: [Base],
  data() {
    return {
      rs_mhs: {},
      dataCout: {}
    }
  }, mounted() {
    this.load_dasboard();
  },
  methods: {

    async load_dasboard() {
      const self = this;
      await axios({
        method: 'PUT',
        url: 'api/admin/load_dasboard',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.dataCout = response.data.result;
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },

  }
}
</script>

<style></style>