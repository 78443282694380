<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import idLocale from '@fullcalendar/core/locales/id' // Import bahasa Indonesia

export default {
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  data: function () {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: 'dayGridMonth',
        events: [

        ],
        locale: idLocale,
        dayHeaderFormat: { weekday: 'long' } // Display full weekday names
      }
    }
  }
}
</script>

<template>
  <div>
    <FullCalendar :options='calendarOptions' />
  </div>
</template>

<!--  Gradasi Hijau
--color-teal-spabattical-100: #ccf6ee;
    --color-teal-spabattical-200: #75e4d5;
    --color-teal-spabattical-300: #48d5c6;
    --color-teal-spabattical-400: #26c0b4;
    --color-teal-spabattical-450: #009990;
    --color-teal-spabattical-500: #00857c;
    --color-teal-spabattical-600: #006b6c;
    --color-teal-spabattical-700: #005c62;
    --color-teal-spabattical-800: #005062;
    --color-teal-spabattical-900: #003440; 
    
    Gradasi Biru
    color-blue-skycicle-200: #abdbff;
    --color-blue-skycicle-300: #75bfff;
    --color-blue-skycicle-400: #45a3fe;
    --color-blue-skycicle-450: #007cff;
    --color-blue-skycicle-500: #0074e8;
    --color-blue-skycicle-600: #005fcb;
    --color-blue-skycicle-700: #004ba9;
    --color-blue-skycicle-800: #003c96;
    --color-blue-skycicle-900: #002966;
    
    // Gradasi merah
        --color-pink-flaminglow-100: #ffdfe9;
    --color-pink-flaminglow-200: #ffbed2;
    --color-pink-flaminglow-300: #fe8eb1;
    --color-pink-flaminglow-400: #f76593;
    --color-pink-flaminglow-450: #ee376a;
    --color-pink-flaminglow-500: #de2c62;
    --color-pink-flaminglow-600: #c31952;
    --color-pink-flaminglow-700: #a30c4a;
    --color-pink-flaminglow-800: #82053e;
    --color-pink-flaminglow-900: #630233;
    -->