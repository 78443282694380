<template>
  <div>
    <b-row>
      <b-col md="6">
      </b-col>
    </b-row>
    <div v-if="st.level_name == '0'">o</div>
    <admin v-if="st.level_name == 'Admin'"></admin>
    <mitra_umkm v-if="st.level_name != 'Admin'"></mitra_umkm>
  </div>
</template>

<script>
import admin from './admin/index.vue';
import Base from '@/config/Mixins_base';
import mitra_umkm from './mitra_umkm/index.vue'
import axios from '@/config/Axios';
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    admin,
    mitra_umkm
  },
  mixins: [Base],
  data() {
    return {
      st: {
        level_name: 'Mahasiswa'
      },
      rs_mhs: {},
    }
  },
  mounted() {
    this.cek_user();
  },
  methods: {
    load(val) {
      this.st = val;
    },
    async cek_user() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/dashboard/cek_user',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.st = response.data.result;
          self.notification('warning', "", response.data.message);

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  }

}
</script>

<style></style>