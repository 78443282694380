<template>
    <div>
        <b-row>
            <b-col md="12">
                <router-link to="/mitra_umkm_peminjaman_alat">
                    <b-card class="position-static card-congratulations bg-danger match-height">
                        <blockquote class="blockquote mb-0">
                            <h4 class="text-white">Pengajuan Peminjaman <strong class="text-white ml-1">({{
                                dataCout.mitra_umkm.pengajuan }})</strong></h4>
                            <footer class="blockquote-footer">
                                <small class="text-muted">
                                    Klik disini untuk melaihat pengajuan alat produksi
                                </small>
                            </footer>
                        </blockquote>
                    </b-card>
                </router-link>
            </b-col>
            <b-col md="12">

                <router-link to="/mitra_umkm_peminjaman_alat">
                    <b-card class="position-static bg-danger card-congratulations  match-height">
                        <blockquote class="blockquote mb-0">
                            <h4 class="text-white">Pengajuan Tervaliadasi <strong class="text-white">({{
                                dataCout.mitra_umkm.acc
                                    }})</strong></h4>
                            <footer class="blockquote-footer">
                                <small class="text-muted">
                                    Klik disini untuk melaihat pengajuan alat produksitervalidasi
                                </small>
                            </footer>
                        </blockquote>
                    </b-card>
                </router-link>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardGroup,
    BCardText,
    BImg
} from 'bootstrap-vue'
export default {
    components: {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BButton,
        BCard,
        BCardGroup,
        BCardText,
        BImg
    },
    props: {
        dataCout: {}
    }
}
</script>

<style></style>