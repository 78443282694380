<template>
  <div>
    <b-row class="match-height">
      <b-col md="4" style="padding: -10px;;">
        <b-img :src="require('@/assets/images/logo/dasboard_admin.png')" class="congratulations-img-left" fluid />
        <info-validasi class="mt-1" :rekap=rekap></info-validasi>
      </b-col>
      <b-col md="8">
        <b-card>
          <b-card-body>
            <calender :events="events"></calender>
          </b-card-body>
        </b-card>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import axios from '@/config/Axios'
import {
  BRow,
  BCol,
  BImg, BCardBody, BCard
} from 'bootstrap-vue'
import InfoValidasi from './component/InfoValidasi.vue'
import calender from '@/views/modul/admin/jadwal_penggunaan_alat/calender.vue'
import time_table from '../../modul/time_table/Calendar.vue'
export default {
  components: {
    BRow,
    BCol,
    InfoValidasi,
    InfoValidasi,
    BImg,
    time_table, BCardBody, BCard, calender
  },
  data() {
    return {
      events: [],
      rekap: [],
      items: [],
      periode: '',
      id_alat_produksi: ''
    }
  },
  mixins: [Base],
  mounted() {
    this.load_dasboard();
    this.calender_list();

  },
  methods: {

    async calender_list() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/admin/jadwal/calender_list',
        data: {
          periode: self.periode,
          id_alat_produksi: self.id_alat_produksi

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.events = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },

    async load_dasboard() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/admin/load_dasboard',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rekap = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
} 
</script>

<style></style>